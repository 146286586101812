body,
* {
  font-family: "Inter", sans-serif !important; /* الافتراضي للعربية */
}

/* اللغة الإنجليزية */
body:lang(en),
*:lang(en) {
  font-family: "Signika", sans-serif !important;
}
.nav-logo {
  width: 4rem;
  height: 4rem;
  margin: 8px 0;
  object-fit: scale-down;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.activeLink {
    color: red;
 }

@media only screen and (max-width: 767px) {
  footer {
    padding: 16px 24px;
  }
}
/* Over Write MUI Class */
.MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}
/* Animate with hover */
.card {
  transition: 0.5s;
}
.card:hover .rotate {
  transition: 0.5s;
  transform: rotate(360deg) scale(1.3);
}
.blur {
  filter: blur(1px);
}
.grayscale {
  filter: grayscale(1);
}
.reset:hover {
  filter: grayscale(0) blur(0);
}

.card_project {
  perspective: 1000px;
  width: 250px;
  height: 250px;
  position: relative;
  margin: 8px auto;
  cursor: pointer;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card_project:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 16px;
  overflow: hidden;
}

.card-back {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateY(180deg);
  padding: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.flash {
  position: relative;
  overflow: hidden;
}
.flash::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255 255 255 / 20%);
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 2;
}
.flash:hover::before {
  animation: flashing 0.7s;
}
.flash {
  max-width: 100%;
  transition: 0.5s;
}
.flash:hover {
  transform: scale(1.1);
}
.translateTop {
  transition: transform 0.5s;
  box-shadow: 0 2px 15px rgb(0 0 0 / 20%) !important;
}
.translateTop:hover {
  transform: translateY(-10px);
  box-shadow: 0 2px 15px rgb(0 0 0 / 20%);
  transition: transform 0.5s;
}

.box:hover svg {
  animation: moving-arrow 0.6s linear infinite;
}

@keyframes flashing {
  0%,
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 200%;
    height: 200%;
  }
}

@keyframes moving-arrow {
  100% {
    transform: translateX(-10px);
  }
}

.description ~ p {
  margin: 0px !important;
}